import { React, useState } from 'react';
import '../assets/styles/NewHome.sass'
import Filter from '../components/Filter';
import { getLocalData } from '../lib/localdata.jsx'
import ReactPlayer from 'react-player';
import Popup from '../components/Popup';
import Footer from '../components/Footer';
import AMozak from '../components/AMozak.js'
import Logo from '../assets/images/Mozak30Anos.png';
import Bsd from '../assets/images/bsd.png';

import 'swiper/css'
import 'swiper/css/navigation'

import Acervo from '../components/Acervo.js';
import Bairros from '../components/Bairros.js';



function NewHome() {
    const [projects, setProjects] = useState(getLocalData().projects);

    return (
        <>
            {/* <Popup /> */}
            {/* <Nav /> */}
            <section id='header'>
                <div>
                    <img src={Logo} alt='Mozak' />
                    <img src={Bsd} alt='bsd' />
                </div>
            </section>
            <section id='home'>
                <h1 className='acervo'>ACERVO</h1>
                <h2 className='frase'>Viva o Rio de <br />um jeito único</h2>
                <div className='filter-field'>
                    <p className='find-text'>o que você está procurando?</p>
                    <Filter projects={projects} setProjects={setProjects} />
                </div>

                <div className='video-overlay'></div>
                <img id='thumb' src='./Home/Thumb.jpg' alt='thumb video' />
                <ReactPlayer
                    className='video-wrapper'
                    url='./Home/AcervoHome.mp4'
                    playing
                    muted
                    width='100%'
                    height='100%'
                    loop
                    onReady={(player) => {
                        const thumb = document.getElementById('thumb');
                        if (thumb) thumb.remove();
                        const video = document.getElementsByClassName('video-wrapper')[0]
                        if (video) video.style.display = 'block'
                    }}
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload', // disable right-click download for some browsers
                                playsInline: true
                            }
                        }
                    }}
                />

            </section>



            <Bairros />
            <Acervo projects={projects} />
            <AMozak />
            <Footer productId={33} />
        </>
    );
}

export default NewHome;